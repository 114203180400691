<template>
  <div>
    <div class="video">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">数字影视</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="content" v-html="con">
      </div>
    </div>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user";
export default {
  data() {
    return {
      con: "",
    };
  },
  created() {
    this.getcompany();
  },
  mounted() {},
  methods: {
    async getcompany() {
      let params = {
        barId: 13,
      };
      const data = await getbannerdetail(params);
      // const regex = new RegExp('<img', 'gi')
      // res.details = details.replace(regex, `<img style="max-width: 100%; height: auto"`);
      // .replace(regex, `<img style="max-width: 100%; height: auto"`)
      this.con = data.data.portalArticleList[0].articleContent;
      // console.log(this.con);
      var address = "http://36.152.65.166:10008";
      // var imgReg = /<img.*?(?:>|\/>)/gi;
      //  var srcReg = /src=['"]?([^'"]*)['"]?/i;
      //  this.con.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function(match, p) {
      //    console.log(p);
      //     return `<img src=' ${address+p}'/>`;
      //   })
      var reg = new RegExp("/dev-api");
      this.con = this.con.replace(
        /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
        function (match, p) {
          return `<img src=' ${address + p.replace(reg, "")}'/>`;
        }
      );
    },
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
.video {
  height: calc(100vh - 271px);
}
.product_title {
  width: 7.5rem;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.6rem;
    .tit1 {
      font-size: 0.3rem;
      font-weight: bold;
      margin-bottom: 0.13rem;
    }
    .tit2 {
      width: 0.6rem;
      height: 0.04rem;
      background-color: #0088ea;
    }
  }
}
.video {
  .content {
    width: 6.5rem;
    margin: 0 auto;
    text-align: justify;
    font-size: 0.25rem;
    // text-indent: 2rem;
    line-height: 0.6rem;
    ::v-deep img {
      display: flex;
      margin: 0.1rem auto;
      width: 100%;
    }
  }
}
</style>